<template>
  <div class="user-manage-table">
    <el-table
      height="70vh"
      :data="userList"
      :empty-text="$t('placeholder.table1')"
      :header-cell-style="{
        fontWeight: 'normal',
        color: 'rgba(112, 112, 112, 1)',
      }"
      :cell-style="{ color: 'rgba(112, 112, 112, 1)' }"
    >
      <el-table-column
        :label="$t('personal.userManagement.table.name')"
        align="left"
        header-align="left"
        width="150"
      >
        <template slot-scope="scope">
          <div class="avatar-with-name-center">
            <personal-avatar
              :size="35"
              :avatar="scope.row.avatar"
              style="margin-right: 10px"
              :nickname="scope.row.nickname"
              :colorid="scope.row.accountId"
            ></personal-avatar>
            <span>{{ scope.row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        :label="$t('personal.userManagement.table.email')"
        align="left"
        header-align="left"
        show-overflow-tooltip
        min-width="20%"
      ></el-table-column>
      <el-table-column
        prop="roles[0].description"
        :label="$t('personal.userManagement.table.role')"
        align="left"
        header-align="left"
        min-width="15%"
      >
      </el-table-column>
      <el-table-column
        v-if="basicLicenseInfo.licenseType === 'MIXED_EDITION'"
        prop="userLicenseType"
        :label="$t('personal.userManagement.table.userType')"
        align="left"
        header-align="left"
        min-width="8%"
      >
        <template slot-scope="scope">
          {{
            scope.row.userLicenseType === "FIXED"
              ? $t("certificate.ENTERPRISE_EDITION")
              : $t("certificate.FLOAT_EDITION")
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="projectNames"
        :label="$t('personal.userManagement.table.projects')"
        align="left"
        header-align="left"
        min-width="20%"
      >
        <template slot-scope="scope">
          {{ scope.row.projectNames.join(",") }}
        </template>
      </el-table-column>
      <el-table-column
        prop="loginStatus"
        :label="$t('personal.userManagement.table.loginStatus')"
        align="left"
        header-align="left"
        min-width="15%"
      >
        <template slot-scope="scope">
          {{ scope.row.loginStatus }}
        </template>
      </el-table-column>
      <el-table-column
        prop="lastActivatedAt"
        align="left"
        header-align="left"
        min-width="15%"
      >
        <template slot="header">
          <span>
            <span class="sortable-column-header" @click="sortLastActiveTime()"
              >{{ $t("personal.userManagement.table.LastActiveTime") }}
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored': lastActiveTimeSort == 0,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored': lastActiveTimeSort == 1,
                  }"
                ></i>
              </div>
            </span>
          </span>
        </template>
        <template slot-scope="scope">
          {{ scope.row.lastActivatedAt }}
        </template>
      </el-table-column>
      <el-table-column label="" width="80" align="right">
        <template slot-scope="scope">
          <el-dropdown>
            <el-button type="text" style="min-height: 16px">
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-edit-outline"
                @click.native="handleEditUser(scope.$index, scope.row)"
              >
                {{ $t("btn.editBtn") }}
              </el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-delete"
                @click.native="handleDeleteUser(scope.$index, scope.row)"
              >
                {{ $t("btn.deleteBtn") }}</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="handleResetPassword(scope.$index, scope.row)"
                v-if="node_env == 'privatization'"
                ><i class="iconfont icon-icon_reset" />
                {{ $t("btn.ResetPassword") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, prev, pager, next, sizes"
      :page-sizes="[10, 20, 50, 100]"
      :total="pageObj.total"
      :current-page="pageObj.currentPage"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
    <!-- 管理员编辑用户资料 -->
    <user-edit-dialog
      :visible.sync="editUserDialog"
      :user="editUser"
      @change="handleEditUserChange"
    ></user-edit-dialog>
    <!-- 管理员重置密码 -->
    <User-reset-dialog
      :visible.sync="resetUserDialog"
      :user="editUser"
    ></User-reset-dialog>
    <el-dialog
      :title="$t('user.userTable.options.transfer')"
      :visible.sync="transferDialog"
      width="30%"
    >
      <span>
        <el-form ref="form" label-position="left" label-width="300px">
          <el-form-item
            v-for="(item, index) in currentProjectList"
            :key="index"
          >
            <template slot="label">
              <el-tooltip
                class="item"
                effect="dark"
                :content="
                  $t('user.userTable.options.transferInfo1') +
                  item.name +
                  $t('user.userTable.options.transferInfo2')
                "
                v-ak-tooltip-auto-show
              >
                <div
                  style="
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  {{
                    $t("user.userTable.options.transferInfo1") +
                    item.name +
                    $t("user.userTable.options.transferInfo2")
                  }}
                </div>
              </el-tooltip>
            </template>
            <el-select
              v-model="transferTo[item.projectId]"
              style="float: right"
              clearable
            >
              <el-option
                v-for="item in item.memberList.filter((user) => {
                  return user && user.accountId !== currentAccountId;
                })"
                :key="item.accountId"
                :label="item.nickname"
                :value="item.accountId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="deleteUser">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="transferDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UserEditDialog from "./UserEditDialog.vue";
import UserResetDialog from "./UserResetDialog.vue";
import {
  delete_user,
  get_tenant_user_list,
  name_search_list,
  joinedProjects,
  tenantTransferAssignee,
} from "@/network/user/index.js";
import "@/components/svgIcon";
import PersonalAvatar from "@/components/personal";

export default {
  components: { UserEditDialog, PersonalAvatar, UserResetDialog },
  props: {
    basicLicenseInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editUser: {
        user: {
          name: "",
          email: "",
        },
        roleId: "",
      },
      editUserDialog: false, // 管理员编辑用户 dialog
      resetUserDialog: false, // 管理员重置成员密码 dialog
      userList: [],
      pageObj: {
        currentPage: 1,
        total: 0,
        size: 10,
      },
      transferDialog: false,
      currentAccountId: "",
      currentAccountIndex: null,
      currentProjectList: [],
      transferTo: {},
      lastActiveTimeSort: 1,
    };
  },
  computed: {
    node_env() {
      return process.env.NODE_ENV;
    },
  },
  mounted() {
    this.get_tenant_user_list();
  },
  methods: {
    sortLastActiveTime() {
      this.lastActiveTimeSort = this.lastActiveTimeSort === 1 ? 0 : 1;
      this.get_tenant_user_list();
    },
    handleCurrentChange(page) {
      this.pageObj.currentPage = page;
      this.get_tenant_user_list();
    },
    handleSizeChange(size) {
      this.pageObj.size = size;
      this.get_tenant_user_list();
    },
    get_tenant_user_list(flag) {
      const params = {
        pageNumber: flag ? 0 : this.pageObj.currentPage - 1,
        pageSize: this.pageObj.size,
        desc: this.lastActiveTimeSort,
      };
      get_tenant_user_list(params).then((res) => {
        this.userList = res.content;
        this.pageObj.total = res.totalElements;
      });
    },
    searchNameFn(name) {
      this.pageObj.currentPage = 1;
      const params = {
        pageNumber: this.pageObj.currentPage - 1,
        pageSize: 10,
        fuzzyName: name,
      };
      name_search_list(params).then((res) => {
        this.userList = res.content;
        this.pageObj.total = res.totalElements;
      });
    },
    handleEditUser(index, row) {
      //console.log(index, row);
      this.editUserDialog = true;
      this.editUser = row;
      this.editIndex = index;
    },
    handleDeleteUser(index, row) {
      if (row.accountId === this.$store.getters.user_info.accountId) {
        this.$message.error(this.$t("personal.edit.info4"));
        return;
      }
      joinedProjects({
        accountId: row.accountId,
      }).then((res) => {
        this.currentAccountId = row.accountId;
        this.currentAccountIndex = index;
        this.currentProjectList = res;
        this.transferDialog = true;
      });
    },
    deleteUser() {
      const params = {
        originalAccountId: this.currentAccountId,
        targetDataList: [],
      };
      this.currentProjectList.forEach((projectInfo) => {
        if (this.transferTo[projectInfo.projectId]) {
          params.targetDataList.push({
            projectId: projectInfo.projectId,
            accountId: this.transferTo[projectInfo.projectId],
          });
        }
      });
      tenantTransferAssignee(params).then((res) => {
        delete_user(this.currentAccountId).then((res) => {
          this.get_tenant_user_list(true);
          this.currentAccountId = "";
          this.currentAccountIndex = null;
          this.currentProjectList = [];
          this.transferDialog = false;
        });
      });
    },
    handleResetPassword(index, row) {
      this.resetUserDialog = true;
      this.editUser = row;
      this.editIndex = index;
    },
    handleEditUserChange(value) {
      //console.log(value);
      this.userList[this.editIndex] = value;
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
<style lang="scss" scoped>
.avatar-with-name-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-manage-table {
  ::v-deep .el-table,
  ::v-deep .el-table tr,
  ::v-deep .el-table th {
    background-color: transparent;
  }
  ::v-deep .el-table {
    overflow: hidden;
  }
}
.sortable-column-header {
  display: flex;
  align-items: center;
  // cursor: pointer;
}
.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}
.el-icon-caret-colored {
  color: #068bff;
}
</style>
